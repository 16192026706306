import React from 'react';
import useDocumentTitle from "../../hooks/useDocumentTitle";
import {pageTitles} from "../../data/data";
import ContentFirst from "./contentFirst";
import Testimonials from "./testimonials";
import ContentSecond from "./contentSecond";
import HeadingCp from "../components/headingCp";

const About = () => {
  useDocumentTitle(pageTitles.about)

  return (
    <>
      <HeadingCp variant={'yellow'} text={'Sobre tututú'} withPadding={true}/>

      <ContentFirst/>

      <Testimonials/>

      <ContentSecond/>
    </>
  );
};

export default About;
