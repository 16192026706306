import burgerKingLogo from '../images/autoconectado/burger-king-logo.png';
import starbucksLogo from '../images/autoconectado/starbucks-logo.png';
import laCajaLogo from '../images/autoconectado/la-caja-logo-2.png';
import testimonialProfileImage from '../images/autoconectado/profile-image-1.png';
import profileImage1 from '../images/staff/profile-image-1.jpg';
import profileImage2 from '../images/staff/profile-image-2.jpg';
import profileImage3 from '../images/staff/profile-image-3.jpg';
import profileImage4 from '../images/staff/profile-image-4.jpg';
import profileImage5 from '../images/staff/profile-image-5.jpg';
import profileImage6 from '../images/staff/profile-image-6.jpg';

export const pageTitles = {
  home: 'Tu Tutú',
  about: 'Tu Tutú - Acerca de nosotros',
}

export const appInfo = {
  downloads: '25.725+',
  androidAppUrl: 'https://play.google.com/store/apps/details?id=com.tu_tutu.tu_tutu_app.lacaja',
  iosAppUrl: 'https://apps.apple.com/ar/app/seguro-auto-conectado-la-caja/id1476832049',
}

export const siteInfo = {
  name: 'Tu Tutú',
  description: 'lorem ipsum',
  meta: {
    keywords: '1, 2, 3'
  },
  email: 'info@tututu.com.ar',
  phone: '+54 11 52587418',
  address: {
    street: 'Av. Leandro N. Alem',
    number: '1002',
    apartment: 'Piso 11',
    zipCode: 'C1001AAS',
    city: 'CABA',
    state: 'Buenos Aires',
    country: 'Argentina',
    geoLocation: {
      lat: '-34.5960215',
      lon: '-58.3727971',
      mapsUrl: 'https://www.google.com/maps/place/-34.5960215,-58.3727971'
    }
  },

}

export const drivingItems = [
  {
    icon: 'fas fa-clock fa-2x',
    title: 'Mantenimiento',
    description: 'Service alarm, Prepay, Parking, etc.',
    link: '#',
  },
  {
    icon: 'fa-solid fa-dharmachakra fa-2x',
    title: 'Conducción',
    description: 'Telematics, Scoring, Smart city, etc.',
    link: '#',
  },
  {
    icon: 'fa-solid fa-car fa-2x',
    title: 'Accidentes',
    description: 'Gestión de siniestros, asistencia y reposición.',
    link: '/contact',
  },
  {
    icon: 'fa-solid fa-dollar-sign fa-2x',
    title: 'Venta',
    description: 'Unidad asegurada, la compra y financiación.',
    link: '#',
  },
  {
    icon: 'fa-solid fa-city fa-2x',
    title: 'Smart City',
    description: 'Maximizar la conducción y mobilidad segura.',
    link: '#',
  },
];

export const featuresItems = [
  {
    id: 1,
    description: 'Confianza en uno mismo y en su habilidad para conducir.',
    icon: 'fa-solid fa-check fa-check-circle-light',
  },
  {
    id: 2,
    description: 'Respeto por las leyes y normas de circulación.',
    icon: 'fa-solid fa-check fa-check-circle-light',
  },
  {
    id: 3,
    description: 'Paciencia para enfrentar con serenidad las dificultades del camino.',
    icon: 'fa-solid fa-check fa-check-circle-light',
  },
  {
    id: 4,
    description: 'Conocimiento de las habilidades y reglamentaciones al conducir',
    icon: 'fa-solid fa-check fa-check-circle-light',
  },
  {
    id: 5,
    description: 'Ceder el paso si es necesario y respetando zonas delimitadas.',
    icon: 'fa-solid fa-check fa-check-circle-light',
  },
  {
    id: 6,
    description: 'Concentración para reaccionar bien antes las adversidades.',
    icon: 'fa-solid fa-check fa-check-circle-light',
  },
  {
    id: 7,
    description: 'Responsable con el cuidado del vehículo.',
    icon: 'fa-solid fa-check fa-check-circle-light',
  },
  {
    id: 8,
    description: 'No realizar acciones que atenten contra los demás.',
    icon: 'fa-solid fa-check fa-check-circle-light',
  },
  {
    id: 9,
    description: 'Conducir con habilidad en todo tipo de terrenos.',
    icon: 'fa-solid fa-check fa-check-circle-light',
  },
  {
    id: 10,
    description: 'Protege la vida de quienes te acompañen.',
    icon: 'fa-solid fa-check fa-check-circle-light',
  },
];

export const securityItems = [
  {
    icon: 'fa-solid fa-check fa-check-circle-light',
    title: 'Asistencia al siniestro:',
    description: 'Contacto directo con el asesor y guía de pasos a seguir.',
  },
  {
    icon: 'fa-solid fa-check fa-check-circle-light',
    title: 'Alerta a tus contáctos:',
    description: 'Notificación de incidentes de las personas dentro del círculo.',
  },
  {
    icon: 'fa-solid fa-check fa-check-circle-light',
    title: 'Mantenimiento del vehículo:',
    description: 'Alertas periódicas y cronograma de visitas estandarizadas.',
  }
];

export const testimonialsItems = [
  {
    id: 1,
    title: 'Historial de viajes y puntajes.',
  },
  {
    id: 2,
    title: 'Detección de incidentes.',
  },
  {
    id: 3,
    title: 'Puntaje a partir de tus hábitos y acciones.',
  },
  {
    id: 4,
    title: 'Historial de viajes y puntajes.',
  },
  {
    id: 5,
    title: 'Detección de incidentes.',
  },
  {
    id: 6,
    title: 'Puntaje a partir de tus hábitos y acciones.',
  },
  {
    id: 7,
    title: 'Historial de viajes y puntajes.',
  },
  {
    id: 8,
    title: 'Detección de incidentes.',
  },
  {
    id: 9,
    title: 'Puntaje a partir de tus hábitos y acciones.',
  },
];

export const appMetrics = [
  {
    title: 'Beneficios otorgados',
    value: '1750',
  },
  {
    title: 'Niveles de usuario',
    value: '6',
  },
  {
    title: 'Usuarios activos',
    value: '7622',
  }
];

export const socialNetworks = [
  {
    id: 1,
    title: 'Instagram',
    url: 'https://instagram.com',
  },
  {
    id: 2,
    title: 'Facebook',
    url: 'https://Facebook.com',
  },
  {
    id: 3,
    title: 'Linkedin',
    url: 'https://Linkedin.com',
  },
];

export const preventionFeatures = [
  {
    id: 1,
    title: 'Trivias para mantenerte enfoco',
    subtitle: 'Educación del conductor',
    description: 'Reglamentación del tráfico, conocimiento de principales factores de prevención, aspectos técnicos del vehículo.'
  },
  {
    id: 2,
    title: 'Indicaciones informativas',
    subtitle: 'Todos los mejores consejos',
    description: 'Situaciones meteorológicas especiales, alertas de manejo contínuo o nocturno y cuidado del vehículo.'
  },
  {
    id: 3,
    title: 'Puntos por kilómetro conducido',
    subtitle: 'Premio a la buena conducta',
    description: 'Medición de velocidades límites según la ruta, aceleraciones indebidas, frenadas bruscas y uso indebido del teléfono.'
  },
];

export const securityFeatures = [
  {
    id: 1,
    title: 'Asesoría y guía de pasos a seguir.',
    subtitle: 'Asistencia en caso de siniestro',
    description: 'Identificador de impacto, que genera una alerta a los servicios de emergencia más próximos.'
  },
  {
    id: 2,
    title: 'Alerta a tus contáctos',
    subtitle: 'Alerta a tu lista de contáctos',
    description: 'Tranquilidad de saber el estado y la ubicación de las personas de interés.'
  },
  {
    id: 3,
    title: 'Mantenimiento del vehículo',
    subtitle: 'Mantenimiento del vehículo',
    description: 'Cada conductor recibe puntos si se cumple con el mantenimiento de su vehículo.'
  },
];

export const benefitsItems = [
  {
    icon: 'fa-solid fa-check-circle',
    title: 'Suma puntos',
    description: '¡Sumá puntos sin gastos ni esfuerzo! Te ofrecemos un sistema de recompensa por puntos y la clasificación que aporta con las personas que mejor conducen, haciendo una firme apuesta a la optimización de la conducción.',
    link: '/',
  },
  {
    icon: 'fa-solid fa-check-circle',
    title: 'Premiate',
    description: '¿Sabías que podés acceder a cupones de descuento para canjearlos por importantes beneficios de las mejores compañías? Sólo contrata tu póliza y obtené recompensas por hacer del mundo un lugar más seguro.',
    link: '/',
  },
  {
    icon: 'fa-solid fa-check-circle',
    title: 'Bonus',
    description: 'Contratá tu póliza al mejor costo, con importantes descuentos que acompañan tu crecimiento al mejorar tus hábitos de conducción. ¡Seguí conduciendo seguro y llegá más rápido a tu próximo premio!',
    link: '/',
  },
  {
    icon: 'fa-solid fa-check-circle',
    title: 'Partners',
    description: 'El análisis predictivo ayuda a nuestros partners a reducir los costos del seguro. A través del sistema de beneficios se fomenta mejoras constantes en el comportamiento de conducción con recompensas positivas a sus clientes.',
    link: '/',
  },
];

export const benefitsFeatures = [
  {
    icon: 'fa-solid fa-check fa-check-circle-light',
    description: 'Retención de clientes actuales',
  },
  {
    icon: 'fa-solid fa-check fa-check-circle-light',
    description: 'Oportunidad de nuevos clientes',
  },
  {
    icon: 'fa-solid fa-check fa-check-circle-light',
    description: 'Datos de habitos de conducción',
  },
  {
    icon: 'fa-solid fa-check fa-check-circle-light',
    description: 'Información de comportamientos',
  },
  {
    icon: 'fa-solid fa-check fa-check-circle-light',
    description: 'Contribuir al concepto de “smart city”',
  },
  {
    icon: 'fa-solid fa-check fa-check-circle-light',
    description: 'Mejor tarifa',
  },
];

export const accordionsList = [
  {
    id: 1,
    title: 'Can I choose my meals?',
    description: 'Quisque rutrum. Aenean imperdi. Etiam ultricies nisi vel augue. Curabitur ullamcorper ultricies nisi. Nam eget dui. Etiam rhoncus. Maecenas tempus, tellus eget.'
  },
  {
    id: 2,
    title: 'When will I receive my order?',
    description: 'Quisque rutrum. Aenean imperdi. Etiam ultricies nisi vel augue. Curabitur ullamcorper ultricies nisi. Nam eget dui. Etiam rhoncus. Maecenas tempus, tellus eget.'
  },
  {
    id: 3,
    title: 'Can I skip a delivery?',
    description: 'Quisque rutrum. Aenean imperdi. Etiam ultricies nisi vel augue. Curabitur ullamcorper ultricies nisi. Nam eget dui. Etiam rhoncus. Maecenas tempus, tellus eget.'
  },
  {
    id: 4,
    title: 'Can I add Extras to my delivery?',
    description: 'Quisque rutrum. Aenean imperdi. Etiam ultricies nisi vel augue. Curabitur ullamcorper ultricies nisi. Nam eget dui. Etiam rhoncus. Maecenas tempus, tellus eget.'
  }
];

export const brandsImages = [
  {
    id: 1,
    title: 'Burger King',
    image: burgerKingLogo,
    link: ''
  },
  {
    id: 2,
    title: 'Starbucks',
    image: starbucksLogo,
    link: ''
  },
  {
    id: 3,
    title: 'La Caja Seguros',
    image: laCajaLogo,
    link: ''
  },
];

export const testimonials = [
  {
    id: 1,
    title: '¡¡Felicitaciones a todos por este inmenso logro!!',
    description: 'Este proyecto fue un enorme desafío para todos, no solo por haber desarrollado un nuevo canal en 7 meses, sino también porque en estas condiciones de pandemia tuvimos que cambiar sobre la marcha nuestra forma de trabajar, de comunicarnos y sobre todo… mantener la motivación, el compromiso y el esfuerzo para hacer esto posible. Es un orgullo que como equipo hayamos llegado a esta instancia pudiendo brindar una mejor y renovada experiencia a nuestros clientes. Nos queda mucho por evolucionar, pero sin dudas este es el inicio de un gran trabajo!',
    user: {
      profileImage: testimonialProfileImage,
      name: 'Sair Vega',
      role: 'Project Manager'
    }
  },
];

export const staffMembers = [
  {
    id: 1,
    name: 'Federico Erbetti',
    role: 'CEO',
    profileImage: profileImage1,
  },
  {
    id: 2,
    name: 'Nazarena Perez',
    role: 'QA Tester',
    profileImage: profileImage2,
  },
  {
    id: 3,
    name: 'Cecilia Suarez',
    role: 'Software Engineer',
    profileImage: profileImage3,
  },
  {
    id: 4,
    name: 'Sebastian Pons',
    role: 'Team Leader',
    profileImage: profileImage4,
  },
  {
    id: 5,
    name: 'Javier Peralta',
    role: 'Software Engineer',
    profileImage: profileImage5,
  },
  {
    id: 6,
    name: 'Sair Venega',
    role: 'Project Manager',
    profileImage: profileImage6,
  },
  {
    id: 7,
    name: 'Lautaro Rojas',
    role: 'QA Tester',
    profileImage: profileImage1,
  },
]
