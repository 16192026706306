import {Container, Row} from "react-bootstrap";
import {testimonials} from "../../data/data";
import Carousel from "react-multi-carousel";
import Testimonial from "../components/testimonial";

const Testimonials = () => {
  return (
    <Container fluid={true} className={'testimonials px-0 pt-5 text-white'}>
      <Container className={' pt-100 pb-100'}
      >
        <Row className={'pt-100'}>
          <Carousel
            className={'brands-carousel'}
            infinite={true}
            arrows={false}
            responsive={{
              superLargeDesktop: {
                // the naming can be any, depends on you.
                breakpoint: {max: 4000, min: 3000},
                items: 1
              },
              desktop: {
                breakpoint: {max: 3000, min: 1024},
                items: 1
              },
              tablet: {
                breakpoint: {max: 1024, min: 464},
                items: 1
              },
              mobile: {
                breakpoint: {max: 464, min: 0},
                items: 1
              }
            }}
          >
            {
              testimonials.map(item => (
                <div key={item.id}>
                  <Testimonial title={item.title} description={item.description} user={item.user}/>
                </div>
              ))
            }
          </Carousel>
        </Row>
      </Container>
    </Container>
  );
};

export default Testimonials;
