import React from 'react';
import {Col, Container, Image, Row} from "react-bootstrap";
import benefitsImage from "../../images/benefits/benefits-image-1.png";
import {benefitsItems, siteInfo} from "../../data/data";
import CardsList from "../components/cardsList";
import CardsListSlider from "../components/cardsListSlider";

const Content = () => {
  return (
    <Container fluid={true} className={'pb-100'}>
      <Container>
        <Row className={'position-relative'}>
          <Col lg={6} className={'text-center'}>
            <Image
              src={benefitsImage}
              alt={siteInfo.name}
              className={'img-fluid position-absolute d-none d-lg-block benefits-image'}
            />
          </Col>

          <Col lg={6}>
            <h4 className={'section-title section-title-green'}>
              BENEFICIOS
            </h4>

            <h5 className={'section-subtitle mb-5'}>
              Descuentos en los costos de tu seguro y beneficios exclusivos
              Aca frase sobre ventajas al usuario no sobre beneficios de la pp
            </h5>

            <p className={'section-content mb-5'}>
              Por cada km conducido sin ningún tipo de estos incidentes, sumas puntos.
            </p>

            <div className={'d-none d-md-block'}>
              <CardsList items={benefitsItems}/>
            </div>

            <div className={'d-md-none'}>
              <CardsListSlider items={benefitsItems}/>
            </div>

          </Col>


        </Row>

      </Container>
    </Container>
  );
};

export default Content;
