import React from 'react';
import {Col, Container, Row} from "react-bootstrap";
import bannerPhoneImage from "../../images/home/home-phone.png";
import androidLogoImage from "../../images/home/android.png";
import appleLogoImage from "../../images/home/apple.png";
import NavBar from "../layout/navBar";
import {appInfo} from "../../data/data";
import {allRoutes} from "../../routes/routes";
import RoundedButton from "../components/roundedButton";
import { LazyLoadImage } from "react-lazy-load-image-component";

const Heading = () => {
  return (
    <div className={'position-relative overflow-hidden'}>
      <LazyLoadImage
        src={bannerPhoneImage}
        className='banner-home-image floating-image'
      />

      <Container
        fluid={true}
        className='home-header main-header clip-path-bg clip-path-bg-dark'
      >
        <NavBar/>

        <Container className={'pt-5'}>
          <Row>
            <Col lg={6} md={6} xs={12}>
              <h3 className={'banner-home-title'}>
                Te damos la bienvenida <br/>al lado seguro <br/>del manejo
              </h3>

              <p className={'banner-home-text py-md-5 py-2'}>
                tututú es la plataforma integradora de características únicas para el conductor como para el
                automóvil.
              </p>

              <Row>
                <Col lg={6}>
                  <RoundedButton text={'Solicitar demo'} link={allRoutes.demo.path}/>
                </Col>

                <Col lg={6} className={'banner-home-downloads-label d-lg-block d-none'}>
                  <p>DESCARGAS</p>
                  <p>{appInfo.downloads}</p>
                </Col>
              </Row>

              <Row className={'banner-home-brands ps-3 pt-4'}>
                <a href={appInfo.iosAppUrl} target={'_blank'} rel={'noreferrer'}>
                  <LazyLoadImage src={appleLogoImage} alt={'Apple log9o'}/>
                </a>

                <a href={appInfo.androidAppUrl} target={'_blank'} rel={'noreferrer'}>
                  <LazyLoadImage src={androidLogoImage} alt={'Android logo'}/>
                </a>
              </Row>

            </Col>
          </Row>

        </Container>

      </Container>
    </div>
  );
};

export default Heading;
