import React from 'react';
import {Col, Image, Row} from "react-bootstrap";

const Testimonial = ({title, description, user}) => {
  return (
    <Row className={'justify-content-between px-5'}>
      <Col lg={4}>
        <h4 className={'section-title section-title-white'}>
          TESTIMONIOS
        </h4>

        <h5 className={'section-subtitle mb-5'}>
          <div dangerouslySetInnerHTML={{__html: title}} />
        </h5>
        <hr/>

        <div className={'user-profile'}>
          <Row>
            <Col xs={2}>
              <Image src={user.profileImage} alt={'Testimonial image'} className={'img-fluid rounded'}/>
            </Col>

            <Col xs={10}>
              <h3>{user.name}</h3>
              <p>{user.role}</p>
            </Col>
          </Row>

        </div>
      </Col>

      <Col lg={6} className={'text-left'}>
        <p className={'section-content mb-5'}>
          {description}
        </p>
      </Col>
    </Row>
  );
};

export default Testimonial;
