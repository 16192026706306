import React from 'react';
import {Col, Container, Row} from "react-bootstrap";
import {staffMembers} from "../../data/data";

const Profiles = () => {
  return (
    <Container fluid={true} className={'pb-100'}>
      <Container>
        <Row className={'members justify-content-around align-content-around'}>
          {
            staffMembers.map(item => (
              <Col lg={3} xs={6}>
                <div className={'members-info text-center'}>
                  <div
                    className={'members-image mb-4'}
                    style={{
                      backgroundImage: `url(${item.profileImage})`
                    }}
                  >
                  </div>
                  <h3>{item.name}</h3>
                  <p>{item.role}</p>
                </div>
              </Col>
            ))
          }
        </Row>
      </Container>
    </Container>
  );
};

export default Profiles;