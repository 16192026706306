import React from 'react';
import Heading from "./heading";
import About from "./about";
import Brands from "./brands";
import Testimonials from "./testimonials";
import Info from "./info";

const Autoconectado = () => {
  return (
    <>
      <Heading/>

      <About/>

      <Brands/>

      <Testimonials/>

      <Info/>
    </>
  );
};

export default Autoconectado;
