import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";

import {allRoutes} from "./routes";

import App from "../pages/layout/app";
import Home from "../pages/home/home";
import About from "../pages/about/about";
import Prevention from "../pages/prevention/prevention";
import Security from "../pages/security/security";
import Benefits from "../pages/benefits/benefits";
import Autoconectado from "../pages/autoconectado/autoconectado";
import Staff from "../pages/staff/staff";
import Contact from "../pages/contact/contact";
import ScrollToTop from "../hooks/scrollToTop";
import Demo from "../pages/demo/demo";

const Router = () => {

  return (
    <BrowserRouter>
      <ScrollToTop/>
      <Routes>
        <Route path="/" element={<App/>}>
          <Route index element={<Home/>}/>
          <Route path={allRoutes.about.path} element={<About/>}/>
          <Route path={allRoutes.prevention.path} element={<Prevention/>}/>
          <Route path={allRoutes.security.path} element={<Security/>}/>
          <Route path={allRoutes.benefits.path} element={<Benefits/>}/>
          <Route path={allRoutes.successCase.path} element={<Autoconectado/>}/>
          <Route path={allRoutes.staff.path} element={<Staff/>}/>
          <Route path={allRoutes.contact.path} element={<Contact/>}/>
          <Route path={allRoutes.demo.path} element={<Demo/>}/>
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default Router;