import React from 'react';
import {Col, Container, Image, Row} from "react-bootstrap";
import autoconectadoHeaderImage from "../../images/autoconectado/autoconectado-header-image.png";
import laCajaLogo from "../../images/autoconectado/la-caja-logo.png";
import autoconectadoLogo from "../../images/autoconectado/autoconectado-logo.png";
import NavBar from "../layout/navBar";
import {siteInfo} from "../../data/data";

const Heading = () => {
  return (
    <>
      <Image
        fluid={true}
        src={autoconectadoHeaderImage}
        className='banner-home-image autoconectado-header-image d-none d-lg-block'
        alt={siteInfo.name}
      />

      <Container
        fluid={true}
        className='home-header main-header clip-path-bg clip-path-bg-dark autoconectado-header'
      >
        <NavBar/>

        <Image
          fluid={true}
          src={autoconectadoHeaderImage}
          className='autoconectado-header-image-responsive d-block d-lg-none'
          alt={siteInfo.name}
        />

        <Container className={'pt-5'}>
          <Row className={'position-relative'}>
            <Col lg={6}>
              <h3 className={'banner-home-title'}>
                Autoconectado
              </h3>

              <p className={'banner-home-text py-5'}>
                La APP de La Caja de Ahorro y Seguro que te premia por conducir adecuadamente en toda la Argentina.
              </p>

              <Row className={'autoconectado-logos-container'}>
                <Image src={laCajaLogo} alt={siteInfo.name} className={'img-fluid'}/>
                <Image src={autoconectadoLogo} alt={siteInfo.name} className={'img-fluid'}/>
              </Row>
            </Col>
          </Row>

        </Container>
      </Container>
    </>
  );
};

export default Heading;
