import React from 'react';
import {Col, Container, Image, Row} from "react-bootstrap";
import staffImage from "../../images/staff/staff-image.png";
import {siteInfo} from "../../data/data";

const Content = () => {
  return (
    <Container fluid={true} className={'pb-100'}>
      <Container>
        <Row>
          <Col lg={6}>
            <h4 className={'section-title section-title-green'}>
              TUTUTÚ
            </h4>

            <h5 className={'section-subtitle mb-5'}>
              Este es nuestro Stall detras de la APP y tras tu seguridad.
            </h5>

            <p className={'section-content mb-5'}>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam vitae pretium eros. Cras faucibus et nisl
              eu dapibus. Nunc pharetra id lorem at convallis. Donec hendrerit, dolor non commodo imperdiet, metus dui
              pellentesque lacus, eu posuere turpis lectus non neque. Praesent dapibus auctor augue sit amet maximus.
              Sed facilisis nisi nec volutpat aliquet. Sed luctus neque congue, mattis mi at, ultricies justo.
              Vestibulum euismod, justo quis porttitor sollicitudin, ex odio laoreet nulla, quis tincidunt purus dui
              vitae nisl.
            </p>

            <p className={'section-content mb-5'}>
              <b>
                La aplicación monitorea y califica el comportamiento del conductor y mide los eventos de conducción por
                viaje.
              </b>
            </p>
          </Col>

          <Col lg={6} className={'text-center'}>
            <Image src={staffImage} alt={siteInfo.name} className={'img-fluid'}/>
          </Col>
        </Row>

      </Container>
    </Container>
  );
};

export default Content;
