import {Col, Container, Image, Row} from "react-bootstrap";
import {siteInfo} from "../../data/data";
import autoconectadoImage from "../../images/autoconectado/autoconectado-image.png";

const Info = () => {
  return (
    <Container fluid={true} className={'pb-100 pt-100 autoconectado-info linear-gradient-white-to-grey'}>
      <Container>
        <Row>
          <Col lg={6}>
            <h4 className={'section-title section-title-green'}>
              AUTOCONECTADO
            </h4>

            <h5 className={'section-subtitle mb-5'}>
              Una APP innovadora<br/>
              y funcional
            </h5>

            <p className={'section-content mb-5'}>
              La nueva plataforma ofrece información personalizada y valiosa al cliente para conocer sus hábitos de manejo para poder mejorarlos.
            </p>

            <p className={'section-content mb-5'}>
              <b>
                Recibe notificaciones que ayudarán a manejar mejor y acceder al programa de Beneficios.
              </b>
            </p>
          </Col>

          <Col lg={6} className={'text-center'}>
            <Image src={autoconectadoImage} alt={siteInfo.name} className={'img-fluid'}/>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default Info;
