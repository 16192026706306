import React from 'react';
import {NavLink} from "react-router-dom";
import {Button} from "react-bootstrap";
import 'hover.css';

const RoundedButton = ({text, link}) => {
  return (
    <Button
      as={NavLink}
      to={link}
      className={'text-white rounded-button rounded-button-md rounded-button-green hvr-icon-wobble-horizontal hvr-grow'}
    >
      {text}
      &emsp;
      <i className="fa-solid fa-circle-arrow-right hvr-icon"/>
    </Button>
  );
};

export default RoundedButton;
