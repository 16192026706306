import React from 'react';
import {Col, Container, Image, Row} from "react-bootstrap";
import benefitsImage2 from "../../images/benefits/benefits-image-2.png";
import {benefitsFeatures, siteInfo} from "../../data/data";
import IconList from "../components/iconList";

const Partners = () => {
  return (
    <Container fluid={true} className={'pb-100 pt-100 container-with-angle'}>
      <Container>
        <Row>

          <Col lg={6}>
            <h4 className={'section-title section-title-green'}>
              BENEFICIOS
            </h4>

            <h5 className={'section-subtitle mb-5'}>
              La estrategia de ser<br/>
              partner de tututú
            </h5>

            <p className={'section-content mb-5'}>
              Pensamos de que manera estratégica cada tipo de partner de tututú de ve beneficiado de la mejor manera.
            </p>

            <p className={'section-content mb-5'}>
              <b>Estas son las ventajas para nuestros aliados estratégicos</b>
            </p>

            <IconList items={benefitsFeatures}></IconList>
          </Col>

          <Col lg={6} className={'text-center partners-image-container position-relative overflow-hidden mt-md-5 pt-md-5'}>
            <Image
              src={benefitsImage2}
              alt={siteInfo.name}
              className={'img-fluid'}
            />
          </Col>


        </Row>

      </Container>

    </Container>
  );
};

export default Partners;
