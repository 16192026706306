import React from 'react';
import {Card, Col, Row} from "react-bootstrap";

const CardsList = ({items}) => {
  return (
    <>
      {
        items.map((items, index) => (
          <div className={'w-100 hvr-icon-pop'} key={index}>
            <Card className={'card-with-shadow mb-4'}>
              <Card.Body>
                <Row>
                  <Col lg={2} xs={2}
                       className={'d-flex justify-content-center align-content-center flex-column text-center'}>
                    <i className={`${items.icon} text-darker-green mt-2 hvr-icon`}/>
                  </Col>
                  <Col lg={10} xs={10}>
                    <Card.Title className={'text-darker-green'}>
                      {items.title}
                    </Card.Title>

                    <Card.Text>
                      {items.description}
                    </Card.Text>
                  </Col>
                </Row>

              </Card.Body>
            </Card>
          </div>
        ))
      }
    </>
  );
};

export default CardsList;
