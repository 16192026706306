import React from 'react';
import Content from "./content";
import Profiles from "./profiles";
import Comments from "./comments";
import Info from "../components/info";
import HeadingCp from "../components/headingCp";

const Staff = () => {
  return (
    <>
      <HeadingCp variant={'dark'} text={'Nuestro equipo'} navBarVariant={'light'} withPadding={true}/>

      <Content/>

      <Profiles/>

      <Comments/>

      <Info
        title={'TUTUTÚ'}
        subtitle={'Trabaja con nosotros'}
        buttonText={'Envíanos tu CV'}
      />
    </>
  );
};

export default Staff;
