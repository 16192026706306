import React from 'react';
import {Container, Image, Nav, Navbar} from "react-bootstrap";
import logoImage from "../../images/global/logo.png";
import {NavLink, useLocation} from "react-router-dom";
import {siteInfo} from "../../data/data";
import {allRoutes, navBarRoutes} from "../../routes/routes";
import "hover.css";
import {LazyLoadImage} from "react-lazy-load-image-component";

const NavBar = ({variant = 'light'}) => {

  const location = useLocation();

  return (
    <>
      <Navbar expand="lg" className={`navbar navbar-${variant}`}>
        <Container className={'pt-4'}>
          <Navbar.Brand
            as={NavLink}
            to={allRoutes.home.path}
            style={{width: "20%"}}
            className={'pe-2'}
          >
            <Image fluid={true} src={logoImage} alt={siteInfo.name}/>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav"/>
          <Navbar.Offcanvas id="basic-navbar-nav">
            <Nav className={"me-auto justify-content-end"}>
              <Nav.Link as={NavLink} to={allRoutes.home.path} className={'d-block d-lg-none offcanvas-logo'}>
                <LazyLoadImage src={logoImage} alt={siteInfo.name}/>
              </Nav.Link>

              {
                navBarRoutes.map((navBarRoute, index) => (
                  <Nav.Link
                    as={NavLink}
                    to={navBarRoute.path}
                    key={index}
                    className={`${navBarRoute.path === location.pathname && navBarRoute !== '/' ? 'active' : ''}`}
                  >
                    {navBarRoute.title}
                  </Nav.Link>
                ))
              }
            </Nav>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
    </>
  );
};

export default NavBar;
