import React from 'react';
import {Col, Container, Image, Row} from "react-bootstrap";
import {siteInfo} from "../../data/data";
import phoneIcon from "../../images/icons/phone.png";

const Header = () => {
  return (
    <Container fluid={true} className={'pb-5'}>
      <Container>
        <Row>
          <Col xs={12}>
            <h4 className={'section-title section-title-green'}>

            </h4>
          </Col>

          <Col lg={8}>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3284.321873503207!2d-58.3727971!3d-34.5960215!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMzTCsDM1JzQ1LjciUyA1OMKwMjInMjIuMSJX!5e0!3m2!1ses!2sar!4v1682519886345!5m2!1ses!2sar"
              width="100%"
              height="450"
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            />
            {/*  <h5 className={'section-subtitle mb-5'}>*/}
            {/*    Completa con tus datos,<br/>*/}
            {/*    el siguiente formulario*/}
            {/*  </h5>*/}

            {/*  <p className={'section-content mb-5'}>*/}
            {/*    Tus datos estan a salvo con nosotros y nos pondremos en contacto contigo a la brevedad.*/}
            {/*  </p>*/}
          </Col>

          <Col lg={4} className={'office-info-container'}>
            <h3>Oficinas</h3>
            <p className={'mt-4'}>
              <a href={siteInfo.address.geoLocation.mapsUrl} target={'_blank'} rel={'noreferrer'}>
                {siteInfo.address.street} {siteInfo.address.number} {siteInfo.address.apartment}<br/>
                {siteInfo.address.zipCode}, {siteInfo.address.city}, {siteInfo.address.country}<br/>
              </a>
            </p>
            <ul className={'list-unstyled mb-5'}>
              {/*<li className={'d-flex justify-content-start py-2'}>*/}
              {/*  <Image src={atIcon} alt={siteInfo.email} className={'img-fluid me-3'}/>*/}
              {/*  <a href={`mailto:${siteInfo.email}`} target={'_blank'} rel={'noreferrer'}>*/}
              {/*    {siteInfo.email}*/}
              {/*  </a>*/}
              {/*</li>*/}

              <li className={'d-flex justify-content-start py-2'}>
                <Image src={phoneIcon} alt={siteInfo.phone} className={'img-fluid me-3'}/>
                <a href={`tel:${siteInfo.phone}`} target={'_blank'} rel={'noreferrer'}>
                  {siteInfo.phone}
                </a>
              </li>
            </ul>
            {/*<h4>Quieres recibir nuestro Newsletter?</h4>*/}
            {/*<Row className={'mt-4'}>*/}
            {/*  <NewsletterForm variant={'xs'}/>*/}
            {/*</Row>*/}
          </Col>
        </Row>

      </Container>
    </Container>
  );
};

export default Header;
