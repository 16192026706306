export const allRoutes = {
  home: {
    title: 'Home',
    path: '/',
  },
  about: {
    name: 'sobre-tu-tutu',
    title: 'Sobre Tu Tutú',
    path: '/sobre-tu-tutu',
  },
  prevention: {
    title: 'Prevención',
    path: '/prevencion',
  },
  security: {
    title: 'Seguridad',
    path: '/seguridad',
  },
  benefits: {
    title: 'Beneficios',
    path: '/beneficios',
  },
  successCase: {
    title: 'Caso de éxito',
    path: '/caso-de-exito',
  },
  contact: {
    title: 'Contacto',
    path: '/contacto',
  },
  demo: {
    title: 'Solicitar demo',
    path: '/demo',
  },
  staff: {
    title: 'Staff',
    path: '/staff',
  },
};

export const navBarRoutes = [
  allRoutes.about,
  allRoutes.prevention,
  allRoutes.security,
  allRoutes.benefits,
  allRoutes.successCase,
  allRoutes.contact,
  // allRoutes.demo,
];

export const sectionsRoutes = [
  allRoutes.about,
  allRoutes.prevention,
  allRoutes.security,
  allRoutes.benefits,
  allRoutes.successCase,
];
