import React from 'react';
import {Card, Col, Container, Image, Row} from "react-bootstrap";
import aboutImage3 from "../../images/about/about-image-3.png";
import {siteInfo, featuresItems, appMetrics} from "../../data/data";
import IconList from "../components/iconList";

const ContentSecond = () => {
  return (
    <Container fluid={true} className={'pb-100 linear-gradient-white-to-grey'}>
      <Container>
        <Row className={'pt-5'}>
          <Col lg={6}>
            <h4 className={'section-title section-title-green'}>
              SOBRE TUTUTÚ
            </h4>

            <h5 className={'section-subtitle mb-5'}>
              Ser consciente es el primer paso
            </h5>

            <IconList items={featuresItems}></IconList>
          </Col>

          <Col lg={6} className={'position-relative text-center'}>
            <Image src={aboutImage3} alt={siteInfo.name} className={'img-fluid mt-4'}/>
            <Card className={'app-metrics-card p-3'}>
              <Card.Body>
                <Row>
                  <Col xs={4}>
                    <p>{appMetrics[0].value}</p>
                    <p>{appMetrics[0].title}</p>
                  </Col>
                  <Col xs={4}>
                    <p>{appMetrics[1].value}</p>
                    <p>{appMetrics[1].title}</p>
                  </Col>
                  <Col xs={4}>
                    <p>{appMetrics[2].value}</p>
                    <p>{appMetrics[2].title}</p>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default ContentSecond;
