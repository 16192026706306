import React from 'react';
import {Container} from "react-bootstrap";
import RoundedButton from "./roundedButton";

const Info = (
  {
    variant = 'lime',
    title = 'title',
    subtitle = 'subtitle',
    buttonText = null,
    link = '/'
  }
) => {
  return (
    <Container fluid={true} className={'text-center pt-100 pb-100 linear-gradient-white-to-grey'}>
      <h4 className={`section-title-alt title-alt-${variant} mb-4`}>
        {title}
      </h4>

      <h5 className={'section-subtitle mb-4'}>
        <div dangerouslySetInnerHTML={{__html: subtitle}} />
      </h5>

      {
        buttonText && (
          <RoundedButton link={link} text={buttonText}/>
        )
      }

    </Container>
  );
};

export default Info;
