import React from 'react';
import useDocumentTitle from "../../hooks/useDocumentTitle";
import {pageTitles} from "../../data/data";
import Driving from "./driving";
import Heading from "./heading";
import Security from "./security";
import Prevention from "./prevention";

const Home = () => {

  useDocumentTitle(pageTitles.home)

  return (
    <>
      <Heading/>

      <Driving/>

      <Security/>

      <Prevention/>
    </>
  );
};

export default Home;
