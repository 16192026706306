import {Col, Container, Row} from "react-bootstrap";

const About = () => {
  return (
    <Container fluid={true} className={'pb-100 pt-100 autoconectado-about'}>
      <Container>
        <Row>
          <Col xs={12}>
            <h4 className={'section-title section-title-green'}>
              AUTOCONECTADO
            </h4>
          </Col>

          <Col lg={6}>
            <h5 className={'section-subtitle mb-5'}>
              El desafío de sacar nuestra <br/>APP a la calle
            </h5>
          </Col>

          <Col lg={6} className={'text-left'}>
            <p className={'section-content mb-5'}>
              <b>
                La Caja de Ahorro y Seguro es un grupo asegurador con sede en la Argentina y ocupa el segundo lugar en
                el
                mercado asegurador argentino con una participación total del 5,9% a diciembre de 2017
              </b>
            </p>
            <p className={'section-content mb-5'}>
              Sin incluir los segmentos de Seguros de Retiro y Riesgos de Trabajo, a esa fecha Caja de Seguros ocupa el
              primer lugar en el Mercado con una participación del 8,4%.
            </p>
            <p className={'section-content mb-5'}>
              Ellos actualmente tiene una app que no ofrecía una experiencia de usuario ajustada a la necesidad que era
              medir el rendimiento del conductor.
            </p>
            <p className={'section-content mb-5'}>
              Los últimos años los comportamientos de los usuarios cambiaron notablemente, así que la caja necesitaba
              optimizar y modernizar la usabilidad de su portal para brindar servicios superiores.
            </p>
            <p className={'section-content mb-5'}>
              Con el objetivo de simplificar y agilizar la experiencia de sus clientes, la caja se renueva con su salida
              de una nueva APP , pensado, diseñado e integrado con las mejores prácticas para ofrecer servicios de
              conciencia a la hora de manejar y así mismo premiando a sus clientes.
            </p>
          </Col>
        </Row>

      </Container>
    </Container>
  );
};

export default About;
