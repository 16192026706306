import React from 'react';
import {Col, Container, Row} from "react-bootstrap";
import preventionImage from '../../images/home/prevention.png';
import {allRoutes} from "../../routes/routes";
import RoundedButton from "../components/roundedButton";
import {LazyLoadImage} from "react-lazy-load-image-component";

const Prevention = () => {
  return (
    <Container fluid={true} className={'bg-gray pb-100 text-center'}>
      <Row className={'justify-content-center'}>
        <Col lg={6} sm={12}>
          <Container>
            <h4 className={'section-title-alt title-alt-light mb-4'}>
              PREVENCIÓN
            </h4>

            <h5 className={'section-subtitle mb-4'}>
              Una conducción respondable,<br/>siempre suma.
            </h5>

            <p className={'section-content mb-5'}>
              Generarás puntos conduciendo, aceptando retos o manteniendo tu auto en buen estado. En tu perfil, podrás
              ver
              la evolución de tu puntaje y los ajustes en el precio de tu póliza.
            </p>
          </Container>

          <Col xs={12}>
            <LazyLoadImage src={preventionImage} alt={'Prevention'} className={'img-fluid'}/>
          </Col>

          <Col xs={12} className={'mt-4'}>
            <RoundedButton text={'Prevención'} link={allRoutes.prevention.path}/>
          </Col>
        </Col>
      </Row>
    </Container>
  );
};

export default Prevention;
