import React from 'react';
import {Col, Container, Image, Row} from "react-bootstrap";
import logoImage from "../../images/global/logo.png";
import {siteInfo, socialNetworks} from "../../data/data";
import {allRoutes, sectionsRoutes} from "../../routes/routes";
import {Link} from "react-router-dom";

const Footer = () => {
  return (
    <div style={{
      backgroundColor: '#e7e7e7'
    }}>
      <Container fluid={true} className={'footer pt-5'}>
        <Container>
          <Row>
            <Col lg={6}>
              <Link to={allRoutes.home.path}>
                <Image src={logoImage} alt={siteInfo.name} className={'mb-4'}/>
              </Link>
              <p className={'address'}>
                <a href={siteInfo.address.geoLocation.mapsUrl} target={'_blank'} rel={'noreferrer'}>
                  {siteInfo.address.street} {siteInfo.address.number} {siteInfo.address.apartment}<br/>
                  {siteInfo.address.zipCode}, {siteInfo.address.city}, {siteInfo.address.country}<br/>
                </a>

                {/*<a href={`mailto:${siteInfo.email}`} target={'_blank'} rel={'noreferrer'}>*/}
                {/*  {siteInfo.email}*/}
                {/*</a>*/}
                {/*&emsp;/&emsp;*/}
                <br/>
                <a href={`tel:${siteInfo.phone}`} target={'_blank'} rel={'noreferrer'}>
                  {siteInfo.phone}
                </a>
              </p>
            </Col>

            <Col lg={3}>
              <h5>SECCIONES</h5>

              <ul className={'list-unstyled'}>
                {
                  sectionsRoutes.map((sectionRoute, index) => (
                    <li key={index}>
                      <Link
                        to={sectionRoute.path}
                        className={'hvr-underline-from-center'}
                      >
                        {sectionRoute.title}
                      </Link>
                    </li>
                  ))
                }
              </ul>
            </Col>
            <Col lg={3}>
              <h5>EN CONTACTO</h5>

              <ul className={'list-unstyled'}>
                {
                  socialNetworks.map(socialNetwork => (
                    <li key={socialNetwork.id}>
                      <a
                        href={socialNetwork.url}
                        target={'_blank'}
                        rel={'noreferrer'}
                        className={'hvr-underline-from-center'}
                      >
                        {socialNetwork.title}
                      </a>
                    </li>
                  ))
                }

                <li>
                  <Link
                    to={allRoutes.contact.path}
                    className={'hvr-underline-from-center'}
                  >
                    {allRoutes.contact.title}
                  </Link>
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </Container>
    </div>
  );
};

export default Footer;
