import React from 'react';
import NavBar from "../layout/navBar";
import {Col, Container, Row} from "react-bootstrap";

const HeadingCp = ({variant = 'yellow', text = 'heading', navBarVariant = 'dark', withPadding = false}) => {
  return (
    <Container
      fluid={true}
      className={`main-header clip-path-bg clip-path-bg-${variant}`}
    >
      <NavBar variant={navBarVariant}/>

      <Container className={'pt-5 text-black'}>
        <Row className={withPadding === true && 'mb-5 mb-md-0'}>
          <Col lg={6} className={withPadding === true && 'pb-5 pb-md-0 mb-5 mb-md-5'}>
            <h3 className={'banner-home-title'}>
              {text}
            </h3>
          </Col>
        </Row>

      </Container>

    </Container>
  );
};

export default HeadingCp;
