import React from 'react';
import {Col, Container, Row} from "react-bootstrap";

const Header = () => {
  return (
    <Container fluid={true} className={'pb-100'}>
      <Container>
        <Row>
          <Col lg={6}>
            <h4 className={'section-title section-title-green'}>
              SOLICITAR DEMO
            </h4>

            <h5 className={'section-subtitle mb-5'}>
              Completa con tus datos,<br/>
              el siguiente formulario
            </h5>

            <p className={'section-content mb-5'}>
              Tus datos estan a salvo con nosotros y nos pondremos en contacto contigo a la brevedad.
            </p>
          </Col>
        </Row>

      </Container>
    </Container>
  );
};

export default Header;
