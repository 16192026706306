import {Card, Col, Container, Image, Row} from "react-bootstrap";
import {brandsImages} from "../../data/data";
import Carousel from "react-multi-carousel";

const Brands = () => {
  return (
    <Container fluid={true} className={'pb-100'}>
      <Container className={'rounded-container rounded-container-gray p-5'}>
        <Row>
          <Col lg={6}>
            <h4 className={'section-title section-title-dark'}>
              NUESTRAS ALIANZAS
            </h4>
            <h5 className={'section-subtitle mb-5'}>
              Nuestros Partners se benefician
            </h5>
          </Col>

          {/*<Col lg={6} className={'pt-5'}>*/}
          {/*  <p className={'section-content mb-5'}>*/}
          {/*    Fidelización, Farming, BBDD, Smart City & Revenue*/}
          {/*  </p>*/}
          {/*</Col>*/}
        </Row>

        <Carousel
          className={'brands-carousel'}
          infinite={true}
          arrows={true}
          responsive={{
            superLargeDesktop: {
              // the naming can be any, depends on you.
              breakpoint: {max: 4000, min: 3000},
              items: 1
            },
            desktop: {
              breakpoint: {max: 3000, min: 1024},
              items: 3
            },
            tablet: {
              breakpoint: {max: 1024, min: 464},
              items: 1
            },
            mobile: {
              breakpoint: {max: 464, min: 0},
              items: 1
            }
          }}
        >
          {
            brandsImages.map(item => (
              <div key={item.id}>
                <Card className={'mx-4'}>
                  <Card.Body className={'p-5'}>
                    <a
                      href={item.link}
                      target={'_blank'}
                      rel={'noreferrer'}
                      className={'bg-white'}
                    >
                      <Image
                        src={item.image}
                        alt={item.title}
                        title={item.title}
                        className={'img-fluid'}
                      />
                    </a>
                  </Card.Body>
                </Card>
              </div>
            ))
          }
        </Carousel>

      </Container>

    </Container>
  );
};

export default Brands;
