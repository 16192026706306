import React from 'react';
import HeadingCp from "../components/headingCp";
import Header from "./header";
import ContactForm from "./contactForm";

const Demo = () => {
  return (
    <>
      <HeadingCp variant={'dark'} text={'Solicitar demo'} navBarVariant={'light'} withPadding={true}/>

      <Header/>

      <ContactForm/>
    </>
  );
};

export default Demo;
