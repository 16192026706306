import {Col, Container, Row} from "react-bootstrap";
import {drivingItems} from "../../data/data";
import CardsList from "../components/cardsList";
import CardsListSlider from "../components/cardsListSlider";

const Driving = () => {
  return (
    <Container className={'driving bg-white pb-100'}>

      <Row>

        <Col xs={12}>
          <h4 className={'section-title section-title-green'}>
            CONDUCCIÓN
          </h4>
        </Col>

        <Col lg={6} className={'pe-5'}>

          <h5 className={'section-subtitle'}>
            Evaluamos junto a vos,<br/>tus hábitos de conducción
          </h5>

          <p className={'section-content mt-5'}>
            <b>tututú</b>
            &nbsp;hace uso de tecnología telemática para ir analizando los patrones de conducción y determinar
            si hay
            incidentes como frenadas bruscas, exceso de velocidad o utilización indebida del teléfono.</p>

          <p className={'section-content mt-5'}>
            <b>Por cada km conducido sin ningún tipo de estos incidentes, sumas puntos.</b>
          </p>
        </Col>

        <Col lg={6}>
          <div className={'d-none d-md-block'}>
            <CardsList items={drivingItems}/>
          </div>

          <div className={'d-md-none'}>
            <CardsListSlider items={drivingItems}/>
          </div>
        </Col>

      </Row>
    </Container>
  );
};

export default Driving;
