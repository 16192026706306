import React from 'react';
import HeadingCp from "../components/headingCp";
import Header from "./header";
import ContactForm from "./contactForm";

const Contact = () => {
  return (
    <>
      <HeadingCp variant={'dark'} text={'Contáctanos'} navBarVariant={'light'} withPadding={true}/>

      <Header/>

      {/*<ContactForm/>*/}
    </>
  );
};

export default Contact;
