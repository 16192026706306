import React from 'react';
import {Col, Container, Row} from "react-bootstrap";
import "react-multi-carousel/lib/styles.css";
import {testimonialsItems} from "../../data/data";
import Slider from "../components/slider";

const Testimonials = () => {

  return (
    <Container fluid={true} className={'pb-100'}>
      <Container className={'rounded-container rounded-container-yellow p-5'} style={{
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
      }}>
        <Row>
          <Col lg={6}>
            <h4 className={'section-title section-title-dark'}>
              TESTIMONIAL
            </h4>
            <h5 className={'section-subtitle mb-5'}>
              Somos la única APP <br/>que te premia por tus <br/>buenos hábitos al volante.
            </h5>
          </Col>

          <Col lg={6} className={'pt-5'}>
            <p className={'section-content mb-5'}>
              Intuitiva, personalizada y autogestiva cambia significativamente la experiencia de los clientes, lo que
              representa un paso más dentro de la transformación cultural de la sociedad digital.
            </p>
          </Col>
        </Row>

      </Container>

      <Slider variant={'yellow'} size={'xs'} arrows={true} items={testimonialsItems} rounded={false}/>

    </Container>
  );
};

export default Testimonials;
