import React from 'react';
import {Col, Container, Image, Row} from "react-bootstrap";
import securityImage from "../../images/security/security-image.png";
import {siteInfo} from "../../data/data";

const Content = () => {
  return (
    <Container fluid={true} className={'pb-100'}>
      <Container>
        <Row>
          <Col lg={6}>
            <h4 className={'section-title section-title-green'}>
              SEGURIDAD
            </h4>

            <h5 className={'section-subtitle mb-5'}>
              Hoy por tí,<br/>
              mañana por los demás
            </h5>

            <p className={'section-content mb-5'}>
              Disfruta de una conducción segura, con la certeza de que estás colaborando a salvar vidas. Cuando conduces con tututú, tus datos están seguros, solo son usados para brindar recompensas por conducir con seguridad.
            </p>
          </Col>

          <Col lg={6} className={'text-center'}>
            <Image src={securityImage} alt={siteInfo.name} className={'img-fluid'}/>
          </Col>
        </Row>

      </Container>
    </Container>
  );
};

export default Content;
