import React from 'react';
import {Col, Container, Image, Row} from "react-bootstrap";
import aboutImage1 from '../../images/about/about-image-1.png';
import aboutImage2 from '../../images/about/about-image-2.png';
import {siteInfo} from "../../data/data";
import {allRoutes} from "../../routes/routes";
import RoundedButton from "../components/roundedButton";

const ContentFirst = () => {
  return (
    <Container fluid={true} className={'pb-100'}>
      <Container>
        <Row>
          <Col lg={6}>
            <h4 className={'section-title section-title-green'}>
              SOBRE TUTUTÚ
            </h4>

            <h5 className={'section-subtitle mb-5'}>
              Queremos estar antes,<br/>durante y después.
            </h5>

            <p className={'section-content mb-5'}>
              <b>Tu Tutú</b>, es una empresa formada por un equipo multidisciplinario apasionado por su trabajo y
              en constante capacitación que ofrece soluciones tecnológicas dedicada al desarrollo de la App
              Autoconectado
              que le ayuda al usuario a mejorar sus hábitos de conducción y lo recompensa por un buen comportamiento.
            </p>

            <p className={'section-content mb-5'}>
              <b>
                Está en constante crecimiento y avance junto con las tecnologías actuales en un mundo que no deja de
                evolucionar.
              </b>
            </p>

            {/*<RoundedButton text={'Nuestro staff'} link={allRoutes.staff.path}/>*/}
          </Col>

          <Col lg={6} className={'text-center'}>
            <Image src={aboutImage1} alt={siteInfo.name} className={'img-fluid'}/>
          </Col>
        </Row>

        <Row className={'pt-5'}>
          <Col lg={6}>
            <Image src={aboutImage2} alt={siteInfo.name} className={'img-fluid'}/>
          </Col>

          <Col lg={6}>
            <h4 className={'section-title section-title-green'}>
              SOBRE TUTUTÚ
            </h4>

            <h5 className={'section-subtitle mb-5'}>
              Estamos de un lado<b/> y del otro del volante
            </h5>

            <p className={'section-content mb-5'}>
              Los Beneficios no son solo para los conductores, sino que los Partners gozan de un plan estratégico de alizanzas.
            </p>

            <RoundedButton text={'Beneficios'} link={allRoutes.benefits.path}/>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default ContentFirst;
