import React from 'react';
import {Col, Container, Image, Row} from "react-bootstrap";
import preventionImage from "../../images/prevention/prevention-image.png";
import {siteInfo} from "../../data/data";

const Content = () => {
  return (
    <Container fluid={true} className={'pb-100'}>
      <Container>
        <Row>
          <Col lg={6}>
            <h4 className={'section-title section-title-green'}>
              PREVENCIÓN
            </h4>

            <h5 className={'section-subtitle mb-5'}>
              Somos optimistas de la buena <br/>conducción.
            </h5>

            <p className={'section-content mb-5'}>
              <b>tututú</b>, es una app gratuita que se dedica a fomentar la conducción positiva basada en ciertas
              premisas, como la seguridad de los demás y la propia. Permite a sus conductores visualizar y mejorar su
              conducción, colaborando a evitar comportamientos que pueden contribuir a accidentes.
            </p>

            <p className={'section-content mb-5'}>
              <b>
                La aplicación monitorea y califica el comportamiento del conductor y mide los eventos de conducción por viaje.
              </b>
            </p>
          </Col>

          <Col lg={6} className={'text-center'}>
            <Image src={preventionImage} alt={siteInfo.name} className={'img-fluid'}/>
          </Col>
        </Row>

      </Container>
    </Container>
  );
};

export default Content;
