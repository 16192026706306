import React from 'react';
import {useForm} from "react-hook-form";
import {Button, Col, Container, Form, Image, Row} from "react-bootstrap";
import {siteInfo} from "../../data/data";
import phoneIcon from "../../images/icons/phone.png"

const ContactForm = () => {
  const {register, handleSubmit, formState: {errors}} = useForm();
  const onSubmit = data => console.log(data);

  return (
    <Container fluid={true} className={'pb-100'}>
      <Container>
        <Row className={'justify-content-between'}>
          <Col lg={6}>
            <Form
              onSubmit={handleSubmit(onSubmit)}
              className={'custom-styled-form'}
            >
              <div className={'input-group flex-column'}>
                <input
                  className={'form-control w-100'}
                  placeholder={'*Nombre y apellido'}
                  {...register("name", { required: true })}
                />
                {errors.name && <p className={'text-danger'}>Cambo obligatorio</p>}
              </div>

              <div className={'input-group flex-column'}>
                <input
                  className={'form-control w-100'}
                  placeholder={'*Empresa'}
                  {...register("company", { required: true })}
                />
                {errors.company && <p className={'text-danger'}>Cambo obligatorio</p>}
              </div>

              <div className={'input-group'}>
                <input
                  className={'form-control'}
                  placeholder={'Dirección'}
                  {...register("address")}
                />
                <input
                  className={'form-control ms-5'}
                  placeholder={'CP'}
                  {...register("zip_code")}
                />
              </div>

              <div className={'input-group'}>
                <input
                  type={'tel'}
                  className={'form-control'}
                  placeholder={'Teléfono'}
                  {...register("phone")}
                />
              </div>

              <div className={'input-group flex-column'}>
                <input
                  type={'email'}
                  className={'form-control w-100'}
                  placeholder={'*E-mail'}
                  {...register("email", { required: true })}
                />
                {errors.email && <p className={'text-danger'}>Cambo obligatorio</p>}
              </div>

              <div className={'input-group flex-column'}>
                <input
                  className={'form-control w-100'}
                  placeholder={'*Motivo'}
                  {...register("subject", { required: true })}
                />
                {errors.subject && <p className={'text-danger'}>Cambo obligatorio</p>}
              </div>

              <div className={'input-group flex-column'}>
                <textarea
                  className={'form-control w-100'}
                  placeholder={'*Mensaje...'}
                  {...register("message", { required: true })}
                />
                {errors.message && <p className={'text-danger'}>Cambo obligatorio</p>}
              </div>

              <div className={'input-group'}>
                <Button
                  type={'submit'}
                  className={'text-white rounded-button rounded-button-md rounded-button-green'}
                >
                  Enviar
                  &emsp;
                  <i className="fa-solid fa-circle-arrow-right"/>
                </Button>
              </div>
            </Form>
          </Col>

          <Col lg={4} className={'office-info-container'}>
            <h3>Oficinas</h3>
            <p className={'mt-4'}>
              <a href={siteInfo.address.geoLocation.mapsUrl} target={'_blank'} rel={'noreferrer'}>
                {siteInfo.address.street} {siteInfo.address.number} {siteInfo.address.apartment}<br/>
                {siteInfo.address.zipCode}, {siteInfo.address.city}, {siteInfo.address.country}<br/>
              </a>
            </p>
            <ul className={'list-unstyled mb-5'}>
              {/*<li className={'d-flex justify-content-start py-2'}>*/}
              {/*  <Image src={atIcon} alt={siteInfo.email} className={'img-fluid me-3'}/>*/}
              {/*  <a href={`mailto:${siteInfo.email}`} target={'_blank'} rel={'noreferrer'}>*/}
              {/*    {siteInfo.email}*/}
              {/*  </a>*/}
              {/*</li>*/}

              <li className={'d-flex justify-content-start py-2'}>
                <Image src={phoneIcon} alt={siteInfo.phone} className={'img-fluid me-3'}/>
                <a href={`tel:${siteInfo.phone}`} target={'_blank'} rel={'noreferrer'}>
                  {siteInfo.phone}
                </a>
              </li>
            </ul>
            {/*<h4>Quieres recibir nuestro Newsletter?</h4>*/}
            {/*<Row className={'mt-4'}>*/}
            {/*  <NewsletterForm variant={'xs'}/>*/}
            {/*</Row>*/}
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default ContactForm;
