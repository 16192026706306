import React from 'react';
import {Col, Container, Row} from "react-bootstrap";
import {securityItems} from "../../data/data";
import securityImage from '../../images/home/security.png';
import IconList from "../components/iconList";
import {allRoutes} from "../../routes/routes";
import RoundedButton from "../components/roundedButton";
import { LazyLoadImage } from 'react-lazy-load-image-component';

const Security = () => {
  return (
    <Container fluid={true} className={'driving bg-gray pt-100 pb-100 overflow-hidden'}>
      <Container>
        <Row>
          <Col xs={12}>
            <h4 className={'section-title section-title-green'}>
              SEGURIDAD
            </h4>
          </Col>

          <Col lg={5}>
            <h5 className={'section-subtitle'}>
              Asistimos cada vez
              que es necesario
            </h5>

            <p className={'section-content mt-5'}>
              Al detectar un incidente, se activa una “alerta roja”. Intentaremos contactarte para asistirte y si es
              necesario se geolocaliza el incidente y se te asiste.
            </p>
            <p className={'section-content my-5'}>
              <b>Puedes crear un círculo de confianza para emitirles esta misma alerta.</b>
            </p>

            <IconList items={securityItems}></IconList>

            <RoundedButton text={'Seguridad'} link={allRoutes.security.path}/>
          </Col>

          <Col lg={7} className={'text-center'}>
            <div className={'security-image-container position-relative mt-5 mt-md-0'}>
              <LazyLoadImage
                src={securityImage}
                alt={'Security'}
                className={'img-fluid security-image'}
              />
            </div>
          </Col>

        </Row>
      </Container>
    </Container>
  );
};

export default Security;
