import React from 'react';
import ContentFirst from "./contentFirst";
import Partners from "./partners";
import Info from "../components/info";
import HeadingCp from "../components/headingCp";
import {allRoutes} from "../../routes/routes";

const Benefits = () => {
  return (
    <>
      <HeadingCp variant={'green'} text={'Consigue, guarda y canjea tus beneficios'} withPadding={true}/>

      <ContentFirst/>

      <Partners/>

      {/*<Info title={'TUTUTÚ'} subtitle={'Nuestros partners<br/>también se benefician'}/>*/}

      {/*<Accordions items={accordionsList}/>*/}

      <Info
        title={'CONTACTO'}
        subtitle={'Tienes dudas o sugerencias?'}
        buttonText={'Contáctanos'}
        link={allRoutes.contact.path}
      />
    </>
  );
};

export default Benefits;
