import React from 'react';
import {Card, Col, Row} from "react-bootstrap";
import Carousel from "react-multi-carousel";

const CardsListSlider = ({items}) => {
  return (
    <Carousel
      className={'carousel-card-list-lg'}
      infinite={true}
      arrows={false}
      showDots={true}
      autoPlay={true}
      responsive={{
        superLargeDesktop: {
          // the naming can be any, depends on you.
          breakpoint: {max: 4000, min: 3000},
          items: 1
        },
        desktop: {
          breakpoint: {max: 3000, min: 1024},
          items: 1
        },
        tablet: {
          breakpoint: {max: 1024, min: 464},
          items: 1
        },
        mobile: {
          breakpoint: {max: 464, min: 0},
          items: 1
        }
      }}
    >
      {
        items.map((items, index) => (
          <Card className={'card-with-shadow mb-4'} key={index}>
            <Card.Body>
              <Row>
                <Col xs={12}
                     className={'d-flex justify-content-center align-content-center flex-column text-center mb-3'}>
                  <i className={`${items.icon} text-darker-green mt-2`}/>
                </Col>

                <Col xs={12}>
                  <Card.Title className={'text-darker-green text-center pt-3'}>
                    {items.title}
                  </Card.Title>

                  <Card.Text className={'text-center py-4'}>
                    {items.description}
                  </Card.Text>
                </Col>
              </Row>

            </Card.Body>
          </Card>
        ))
      }
    </Carousel>
  );
};

export default CardsListSlider;
