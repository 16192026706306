import React from 'react';
import Carousel from "react-multi-carousel";
import {Card, Col, Container, Row} from "react-bootstrap";

const Slider = (
  {
    variant = 'yellow',
    size = 'xs',
    items,
    responsive = {
      superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: {max: 4000, min: 3000},
        items: 1
      },
      desktop: {
        breakpoint: {max: 3000, min: 1024},
        items: 3
      },
      tablet: {
        breakpoint: {max: 1024, min: 464},
        items: 1
      },
      mobile: {
        breakpoint: {max: 464, min: 0},
        items: 1
      }
    },
    infinite = true,
    arrows = true,
    autoplay = true,
    rounded = true,
  }
) => {
  return (
    <Container className={`${rounded ? `rounded-container` : ''} rounded-container-${variant} p-5`}>
      <div className={'carousel-container'}>
        <Carousel
          infinite={infinite}
          arrows={arrows}
          responsive={responsive}
          autoplay={autoplay}
        >
          {
            items.map(item => (
              <div key={item.id}>
                <Card className={`px-4 ${size === 'lg' ? 'card-tall' : ''}`}>
                  {
                    size === 'lg'
                      ?
                      (<Card.Body
                        className={'text-start px-4 d-flex flex-column justify-content-center align-content-center'}>
                        <h4 className={'card-body-title'}>{item.title}</h4>
                        <h3 className={'card-body-subtitle'}>{item.subtitle}</h3>
                        <Row>
                          <Col xs={1}>
                            <p>
                              <i className={`fa-solid fa-check fa-check-circle-light text-darker-green`}/>
                              &emsp;
                            </p>
                          </Col>

                          <Col xs={11}>
                            <p className={'card-body-description'}>{item.description}</p>
                          </Col>
                        </Row>

                      </Card.Body>)
                      :
                      <Card.Body>
                        <Card.Text>
                          {item.title}
                        </Card.Text>
                      </Card.Body>
                  }
                </Card>
              </div>
            ))
          }
        </Carousel>

      </div>
    </Container>
  );
};

export default Slider;
