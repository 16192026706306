import React from 'react';
import Content from "./content";
import Slider from "../components/slider";
import {preventionFeatures} from "../../data/data";
import Info from "../components/info";
import HeadingCp from "../components/headingCp";
import {allRoutes} from "../../routes/routes";

const Prevention = () => {
  return (
    <>
      <HeadingCp
        variant={'lime'}
        text={'La prevención es nuestro punto de partida'}
        withPadding={true}
      />

      <Content/>

      <Slider
        variant={'lime'}
        size={'lg'}
        arrows={false}
        items={preventionFeatures}
        infinite={true}
        autoplay={true}
      />

      <Info
        variant={'lime'}
        title={'Tu Tutú'}
        subtitle={'No es solo una APP, es un voto de confianza.'}
        buttonText={'Sobre Tu Tutú'}
        link={allRoutes.about.path}
      />
    </>
  );
};

export default Prevention;
