import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './styles/app.scss';
import "bootstrap/dist/js/bootstrap.bundle.min";
import Router from "./routes/router";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Router/>
);