import React from 'react';
import Content from "./content";
import {securityFeatures} from "../../data/data";
import Slider from "../components/slider";
import Info from "../components/info";
import HeadingCp from "../components/headingCp";
import {allRoutes} from "../../routes/routes";

const Security = () => {
  return (
    <>
      <HeadingCp variant={'light-green'} text={'La seguridad es nuestro único destino'} withPadding={true}/>

      <Content/>

      <Slider
        variant={'green'}
        size={'lg'}
        arrows={false}
        items={securityFeatures}
      />

      <Info
        variant={'lime'}
        title={'Tu Tutú'}
        subtitle={'No es solo una APP, es un voto de confianza.'}
        buttonText={'Sobre Tu Tutú'}
        link={allRoutes.about.path}
      />
    </>
  );
};

export default Security;
