import React from 'react';
import {Col, Row} from "react-bootstrap";

const IconList = ({items}) => {
  return (
    <ul className={'list-unstyled'}>
      {
        items.map((item, index) => (
          <li key={index}>
            <Row>
              <Col xs={1}>
                <p>
                  <i className={`${item.icon} text-darker-green`}/>
                  &emsp;
                </p>
              </Col>

              <Col xs={11}>
                <p className={'line-height-30'}>
                  <b>{item.title}</b> {item.description}
                </p>
              </Col>
            </Row>
          </li>
        ))
      }
    </ul>
  );
};

export default IconList;
